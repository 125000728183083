import {
  forEach, trim, find, assign,
} from 'lodash';

const COMPACT_VIEW_HEIGHT = 44;
const COMFORT_VIEW_HEIGHT = 55;
const MIN_COLUMN_WIDTH = 50;

// SORT ACTIONS
export const getSortTooltipText = (column, selectedSort) => {
  if (!column || !selectedSort) return 'Unknown sorting';
  let tooltipText = 'Sort in ';
  let orderText = 'ascending';
  const currentOrder = selectedSort.order;
  if (column.key === selectedSort.field) {
    tooltipText = 'Sorted in ';
    orderText = currentOrder === 'ASC' ? 'ascending' : 'descending';
  }
  tooltipText += orderText;
  return tooltipText;
};

export const getSortIcon = (column, selectedSort) => {
  let sortIcon = 'arrow-up';
  if (!column || !selectedSort) return sortIcon;
  if (column.key === selectedSort.field) {
    sortIcon = selectedSort.order === 'ASC' ? 'arrow-up' : 'arrow-down';
  }
  return sortIcon;
};

// COLUMN RESIZE ACTIONS
export const getColumnCurrentWidth = (columnClass) => {
  const columnElement = document.getElementsByClassName(columnClass)[0];
  const columnElementMinWidth = columnElement?.clientWidth;
  return columnElementMinWidth;
};

export const resizeColumn = (
  columns,
  columnKey,
  mouseXPositionDifference,
  resizeColumnInitialWidth,
  resetResize,
  tableId,
) => {
  const tableScroller = document.getElementById(tableId ? `${tableId}--table-scroller` : 'table-scroller');
  const resizedColumn = find(columns, (column) => column.key === columnKey);
  let newWidth;

  if (tableScroller.clientWidth === tableScroller.scrollWidth) {
    newWidth = resizeColumnInitialWidth + mouseXPositionDifference;
    if (newWidth < MIN_COLUMN_WIDTH) return;
    assign(resizedColumn, {
      minWidth: newWidth,
      maxWidth: newWidth,
    });
  } else {
    const excededValue = tableScroller.scrollWidth - tableScroller.clientWidth;
    const currentWidth = resizedColumn.minWidth;
    newWidth = currentWidth - excededValue;
    assign(resizedColumn, {
      minWidth: newWidth,
      maxWidth: newWidth,
    });
    resetResize();
  }
};
export const generateGridTemplateRowsHeight = (numberOfRows, rowSpacingOption) => {
  const rowHeight = rowSpacingOption === 'comfort-view' ? COMFORT_VIEW_HEIGHT : COMPACT_VIEW_HEIGHT;
  const gridTemplateRows = `repeat(${numberOfRows + 1}, ${rowHeight}px)`;
  return gridTemplateRows;
};

export const generateGridTemplateColumnsWidth = (columns) => {
  let gridTemplateColumnsValue = '';

  forEach(columns, (column) => {
    const minWidth = column.minWidth ? `${column.minWidth}px` : '50px';
    const maxWidth = column.maxWidth ? `${column.maxWidth}px` : '1fr';
    gridTemplateColumnsValue += `minmax(${minWidth}, ${maxWidth}) `;
  });
  return trim(gridTemplateColumnsValue);
};

export const generateGridTemplateColumns = (columns, selectable) => {
  const columnsWidth = [];
  // Initial column reserved for checbox - row select
  if (selectable) columnsWidth.push({ key: 'selected', minWidth: 70, maxWidth: 70 });
  forEach(columns, ({ key, minWidth, maxWidth }) => {
    columnsWidth.push({
      key,
      minWidth,
      maxWidth,
    });
  });
  return columnsWidth;
};

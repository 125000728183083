<template>
  <div
    ref="dropdownRoot"
    @click="toggleDropdown"
    :class="['checkbox-dropdown--root', dropdownActive && 'active']"
  >
    <Checkbox
      @click="checkboxClicked"
      :model-value="modelValue"
      :disabled="disabled"
      :intermediate="modelValue"
    />
    <Icon
      class="chevron-icon"
      :name="dropdownActive ? 'chevron-up' : 'chevron-down'"
    />
    <div :class="['dropdown-root', dropdownActive && 'active']">
      <div
        v-for="action in actions"
        :key="action.key"
        :class="['dropdown-item', (selectedValue === action.key && modelValue) && 'active']"
        @click="onValueSelected(action.key)"
      >
        {{ action.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Checkbox from './Checkbox';
import Icon from './Icon';

export default {
  emits: ['selected:value'],
  components: {
    Checkbox,
    Icon,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    selectedItemsLength: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const dropdownRoot = ref(null);
    const dropdownActive = ref(false);
    const selectedValue = ref(null);
    const modelValue = computed(() => (!props.selectedItemsLength ? false : !!selectedValue.value));

    const toggleDropdown = () => {
      if (props.disabled) return;
      dropdownActive.value = !dropdownActive.value;
    };

    const checkboxClicked = () => {
      if (!dropdownActive.value && !selectedValue.value) {
        dropdownActive.value = true;
      } else if (selectedValue.value) {
        selectedValue.value = '';
        emit('selected:value', selectedValue.value);
        dropdownActive.value = false;
      }
    };

    const onValueSelected = (value) => {
      selectedValue.value = value;
      emit('selected:value', value);
    };

    onClickOutside(dropdownRoot, () => {
      dropdownActive.value = false;
    });

    return {
      dropdownActive,
      toggleDropdown,
      selectedValue,
      modelValue,
      checkboxClicked,
      dropdownRoot,
      onValueSelected,
    };
  },
};
</script>

<style lang="scss">
.checkbox-dropdown--root {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 4px;
    border-radius: 4px;
    cursor: pointer;

    &:hover, &.active {
        background-color: $gray400;
    }

    .chevron-icon {
        stroke: $gray600;
        margin-left: 7px;
    }

    .dropdown-root {
        min-width: 200px;
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        padding: 4px 0px;
        background: #fff;
        box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        color: $black;
        z-index: 1;

        &.active {
            display: block;
        }

        .dropdown-item {
            padding: 13px 8px;

            &:hover, &.active {
                background-color: $gray300;
            }
        }
    }
}
</style>
